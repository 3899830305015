import { createStore } from 'vuex'

export default createStore({
  state: {
    loading: true,
    code: 7153208,
    metaverse: {
      code: 7153208,
      title: "Loading",
      background: "",
      logo: ""
    },
    user: null,
    unityConfig: { }
  },
  getters: {
  },
  mutations: {
    setCode(state, code) {
      state.code = code;
    },
    setMetaverse (state, metaverse) {
      state.metaverse = metaverse
    },
    setLoading (state, loading) {
      state.loading = loading
    },
    setUser (state, user) {
      state.user = user
    },
    setConfig (state, unityConfig) {
      state.unityConfig = unityConfig
    },
  },
  actions: {
    async readMetaverse({ commit, state }, configType) {
      var url = `./api/readMetaverse.php?code=${state.code}`;
      try {
        var response = await fetch(url);
        if (response.ok) {
          var data = await response.json();
          console.log("Successfully read metaverse data:", data);
          commit("setMetaverse", data);
          url = `https://api.legacy.holofair.io/build/read.php?type=${configType}&code=${state.code}`;
          try {
            response = await fetch(url);
            if (response.ok) {
              data = await response.json();
              console.log("Successfully read unity config data:", data);
              commit("setConfig", data);
              commit("setLoading", false);
            } else {
              console.log("Failed to read unity config:", response.statusText);
            }
          } catch (error) {
            console.log("An error occurred:", error);
          }
        } else {
          console.log("Failed to read metaverse:", response.statusText);
        }
      } catch (error) {
        console.log("An error occurred:", error);
      }
    }
  },
  modules: {
  }
})
