<template>
  <n-config-provider :theme="theme" style="height: 100%;">
    <div id="background" :style="background">
      <router-view/>
    </div>
    <n-button id=darklightmodebtn circle @click="toggleDarkLight()">
      <template #icon>
        <n-icon><bulb-icon/></n-icon>
      </template>
    </n-button>
  </n-config-provider>
</template>

<style>
#background {
  background: linear-gradient(rgba(0, 0, 0, 0.35), rgba(0, 0, 0, 0.35)), url("assets/default_background.jpg");
  background-size: cover;
  height: 100%;
  background-repeat: no-repeat;
}

#darklightmodebtn {
  position: absolute;
  top: 24px;
  right: 24px;
}

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  max-height: 100vh;
  height: 100%;
}

.center {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1;
}

nav a {
  font-weight: bold;
  color: #2c3e50;
}

nav a.router-link-exact-active {
  color: #42b983;
}
</style>

<script setup>
import { BulbOutline as BulbIcon } from "@vicons/ionicons5";
import { computed, ref } from 'vue'
import { useStore } from 'vuex'
import { darkTheme } from 'naive-ui'

const store = ref(useStore())

const theme = ref(darkTheme);

const background = computed(() => {
  return `background-image: linear-gradient(rgba(0, 0, 0, 0.35), rgba(0, 0, 0, 0.35)), url("${store.value.state.metaverse.background}");`;
})

function toggleDarkLight() {
  if(theme.value == null) theme.value = darkTheme;
  else theme.value = null;
}
</script>